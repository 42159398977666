import { Component, OnInit } from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ApiService} from '../../services/api.service';


@Component({
  selector: 'app-demo',
  templateUrl: './demo.component.html',
  styleUrls: ['./demo.component.scss']
})
export class DemoComponent implements OnInit {
  contactForm: FormGroup = new FormGroup({
    firstName: new FormControl(null, Validators.required),
    lastName: new FormControl(null, Validators.required),
    email: new FormControl(null, [Validators.required, Validators.email]),
    phoneNumber: new FormControl(null),
    practiceName: new FormControl(null),
  });

  buttonText = 'Submit';

  constructor(private apiService: ApiService) { }

  ngOnInit(): void {
    this.contactForm.reset();
  }

  submitForm(): void {
    this.buttonText = 'Thanks!';
    const form = {
      Name: this.contactForm.controls.firstName.value + ' ' + this.contactForm.controls.lastName.value,
      Email: this.contactForm.controls.email.value,
      PhoneNumber: this.contactForm.controls.phoneNumber.value,
      PracticeName: this.contactForm.controls.practiceName.value
    };
    this.contactForm.reset();
    this.apiService.sendForm(form).subscribe(x => {
      setTimeout(() => {
        this.buttonText = 'Submit';
      }, 3000);
    }, error => {
      console.log(error);
    });
  }

}

<div class="modal-body">
    <div class="modal-title mb-3" i18n>Request Demo</div>
    <p class="modal-subtitle mb-4" i18n>
        To receive more information on Tetherport for Specialist Clinics and Dental Labs, please provide your
        information in the form below and someone from our team will contact you directly.
    </p>
    <form class="teth-textfield-form px-0" [formGroup]="contactForm" [hidden]="formSent">
        <div class="row px-0">
            <div class="col-12"
                 [class.error]="contactForm.controls.companyType.invalid && (contactForm.controls.companyType.dirty || contactForm.controls.companyType.touched)">
                <label class="teth-textfield-label" for="companyType" i18n>Type of Business</label>
                <select class="teth-dropdown teth-textfield-dropdown"
                        name="companyType"
                        id="companyType"
                        [formControlName]="'companyType'">
                    <option [ngValue]="'Specialist'" i18n>Specialist Clinic</option>
                    <option [ngValue]="'DentalLab'" i18n>Dental Lab</option>
                </select>
                <span class="teth-textfield-error"></span>
            </div>
            <div class="col-12"
                 [class.error]="contactForm.controls.companyName.invalid && (contactForm.controls.companyName.dirty || contactForm.controls.companyName.touched)">
                <label class="teth-textfield-label" for="companyName" i18n>Company Name</label>
                <input class="teth-textfield" type="text" id="companyName" name="companyName"
                       i18n-placeholder
                       placeholder="Company Name"
                       [formControlName]="'companyName'">
                <span class="teth-textfield-error" i18n>Company name is required</span>
            </div>
            <div class="col-12"
                 [class.error]="contactForm.controls.contactName.invalid && (contactForm.controls.contactName.dirty || contactForm.controls.contactName.touched)">
                <label class="teth-textfield-label" for="contactName" i18n>Contact Name</label>
                <input class="teth-textfield" type="text" id="contactName" name="contactName"
                       placeholder="Contact Name"
                       i18n-placeholder
                       [formControlName]="'contactName'">
                <span class="teth-textfield-error" i18n>Contact name is required</span>
            </div>
            <div class="col-12"
                 [class.error]="contactForm.controls.email.invalid && (contactForm.controls.email.dirty || contactForm.controls.email.touched)">
                <label class="teth-textfield-label" for="email" i18n>Contact Email</label>
                <input class="teth-textfield" type="text" id="email" name="email"
                       placeholder="Email"
                       i18n-placeholder
                       [formControlName]="'email'">
                <span class="teth-textfield-error" i18n>A valid email is required</span>
            </div>
            <div class="col-6"
                 [class.error]="contactForm.controls.phoneNumber.invalid && (contactForm.controls.phoneNumber.dirty || contactForm.controls.phoneNumber.touched)">
                <label class="teth-textfield-label" for="phoneNumber" i18n>Contact Phone Number</label>
                <input class="teth-textfield" type="text" id="phoneNumber" name="phoneNumber"
                       placeholder="Phone Number"
                       i18n-placeholder
                       [formControlName]="'phoneNumber'">
                <span class="teth-textfield-error" i18n>Contact phone number is required</span>
            </div>
            <div class="col-12 mt-3 d-flex justify-content-end">
                <button class="teth-form-button ml-3"
                        (click)="close()" i18n>
                    Cancel
                </button>
                <button class="teth-form-button teth-form-button-preferred ml-3" [class.disabled]="!this.contactForm.valid"
                        (click)="submitForm()" i18n>
                    Submit
                </button>
            </div>
        </div>
    </form>
    <div class="row px-0" [hidden]="!formSent">
        <div class="col-12 d-flex justify-content-center">
            <img class="success-checkmark" [src]="'assets/img/check-circle-green.svg'" alt="">
        </div>
        <div class="col-12 d-flex justify-content-center">
            <div class="success-message mt-4" i18n>
                Thank you. We will reach out to you by email within two business days.
            </div>
        </div>
        <div class="col-12 mt-5 d-flex justify-content-end">
            <button class="teth-form-button teth-form-button-preferred ml-3"
                    (click)="close()" i18n>
                Continue
            </button>
        </div>
    </div>
</div>

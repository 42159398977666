<div class="container-fluid">
    <div class="row get-started teth-row">
        <div class="col get-started-text text-center vertical-center" i18n>
            Get Started Today.
        </div>
    </div>
</div>
<div class="container-fluid teth-container demo-row">
    <div class="row">
        <div class="col-lg-5 col-12">
            <p class="prac-title" i18n>Get Started Today</p>
            <p class="prac-subtitle mb-3" i18n>Keep Your Finger on the Pulse of Your Referrals</p>
            <p class="prac-details mb-3" i18n>
                Find out how implementing an accurate, efficient and secure referral portal will benefit your business.
            </p>
        </div>
        <div class="col-lg-5 offset-lg-1 col-12">
            <p class="request-text" i18n>Request your free <span class="inline-teth"><img [src]="'assets/img/tetherport-wordmark-blue.svg'"></span> demo.</p>
            <div class="row">
                <div class="col-lg-6 col-12">
                    <form class="teth-textfield-form" [formGroup]="contactForm"
                          [class.error]="contactForm.controls.firstName.invalid && (contactForm.controls.firstName.dirty || contactForm.controls.firstName.touched)">
                        <input class="teth-textfield" type="text" id="firstName" name="firstName"
                               placeholder="Your First Name"
                               i18n-placeholder
                               [formControlName]="'firstName'">
                        <span class="teth-textfield-error" i18n>First name is required</span>
                    </form>
                </div>
                <div class="col-lg-6 col-12">
                    <form class="teth-textfield-form" [formGroup]="contactForm"
                          [class.error]="contactForm.controls.lastName.invalid && (contactForm.controls.lastName.dirty || contactForm.controls.lastName.touched)">
                        <input class="teth-textfield" type="text" id="lastName" name="lastName"
                               placeholder="Your Last Name"
                               i18n-placeholder
                               [formControlName]="'lastName'">
                        <span class="teth-textfield-error" i18n>Last name is required</span>
                    </form>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-6 col-12">
                    <form class="teth-textfield-form" [formGroup]="contactForm"
                          [class.error]="contactForm.controls.email.invalid && (contactForm.controls.email.dirty || contactForm.controls.email.touched)">
                        <input class="teth-textfield" type="email" id="email" name="email"
                               placeholder="Email Address"
                               i18n-placeholder
                               [formControlName]="'email'">
                        <span class="teth-textfield-error" i18n>Valid email is required</span>
                    </form>
                </div>
                <div class="col-lg-6 col-12">
                    <form class="teth-textfield-form" [formGroup]="contactForm"
                          [class.error]="contactForm.controls.phoneNumber.invalid && (contactForm.controls.phoneNumber.dirty || contactForm.controls.phoneNumber.touched)">
                        <input class="teth-textfield" type="tel" id="phoneNumber" name="phoneNumber"
                               placeholder="Phone Number"
                               i18n-placeholder
                               [formControlName]="'phoneNumber'">
                        <span class="teth-textfield-error" i18n>Valid phone number is required</span>
                    </form>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12 col-12">
                    <form class="teth-textfield-form" [formGroup]="contactForm"
                          [class.error]="contactForm.controls.practiceName.invalid && (contactForm.controls.practiceName.dirty || contactForm.controls.practiceName.touched)">
                        <input class="teth-textfield mb-4" type="text" id="practiceName" name="practiceName"
                               placeholder="Practice Name"
                               i18n-placeholder
                               [formControlName]="'practiceName'">
                        <span class="teth-textfield-error"></span>
                        <button class="teth-button navy-button" [disabled]="!this.contactForm.valid" [class.grey-button]="!this.contactForm.valid" (click)="submitForm()">{{buttonText}}</button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="container-fluid">
    <div class="row img-row">
    </div>
</div>

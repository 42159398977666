<div class="container-fluid teth-container">
    <div class="row row-flip">
        <div class="col-lg-4 col-12 align-self-center">
            <p class="prac-title" i18n>Grow Your Referral Base</p>
            <p class="prac-subtitle mb-3" i18n>Enhance and Grow Your Practice with a Secure Referral Management System</p>
            <p class="prac-details mb-3" i18n>Understand where referrals are coming from and uncover ways that Tetherport
                will help you to increase your overall case acceptance.</p>
            <button [routerLink]="'/demo'" class="teth-button navy-button mb-4" i18n>Learn More</button>
        </div>
        <div class="col-lg-6 col-12 offset-lg-1">
            <img class="img-fluid" [src]="'assets/img/specialists/specialist-hero.svg'" alt="Tetherport for Specialists">
        </div>
    </div>
    <div class="row">
        <div>
            <img class="img-fluid mt-4" [src]="'assets/img/specialists/tetherport-for-specialists.svg'">
        </div>
    </div>
    <div class="row teth-row">
        <div class="col-lg-6 col-12">
            <img class="img-fluid" [src]="'assets/img/specialists/specialist-1.svg'" alt="Tetherport Specialist Dashboard 1">
        </div>
        <div class="col-lg-4 align-self-center offset-lg-1 col-12">
            <div class="prac-circle specialist-circle">
                1
            </div>
            <p class="why-title" i18n>Manage and Track All Incoming Referrals</p>
            <p class="why-details" i18n>Accept incoming referrals 24/7 and minimize lost revenue due to missed referrals.</p>
        </div>
    </div>
    <div class="row teth-row grey-row row-flip">
        <div class="col-lg-4 align-self-center col-12">
            <div class="prac-circle specialist-circle">
                2
            </div>
            <p class="why-title" i18n>Business Intelligence for Data-driven Insights</p>
            <p class="why-details" i18n>Rely on data analysis, recommendation algorithms and artificial intelligence to
                generate strategies for increasing total referrals and optimizing clinic performance.</p>
        </div>
        <div class="col-lg-6 offset-lg-1 col-12">
            <img class="img-fluid" [src]="'assets/img/specialists/specialist-2.svg'" alt="Tetherport Specialist Dashboard 2">
        </div>
    </div>
    <div class="row teth-row">
        <div class="col-lg-6 col-12">
            <img class="img-fluid" [src]="'assets/img/specialists/specialist-3.svg'" alt="Tetherport Specialist Dashboard 3">
        </div>
        <div class="col-lg-4 align-self-center offset-lg-1 col-12">
            <div class="prac-circle specialist-circle">
                3
            </div>
            <p class="why-title" i18n>Send Outgoing Referral Case Correspondence</p>
            <p class="why-details" i18n>Simplify the creation of clinical correspondence, allowing dental specialists to quickly and
                efficiently provide legal documentation back to referring dentists.</p>
        </div>
    </div>
</div>

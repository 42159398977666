import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ApiService} from '../../services/api.service';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

export type CompanyType = 'Specialist' | 'Dentist' | 'DentalLab';

@Component({
    selector: 'app-request-demo-modal',
    templateUrl: './request-demo-modal.component.html',
    styleUrls: ['./request-demo-modal.component.scss']
})
export class RequestDemoModalComponent implements OnInit {

    contactForm: FormGroup = new FormGroup({
        companyType: new FormControl(null, Validators.required),
        contactName: new FormControl(null, Validators.required),
        companyName: new FormControl(null, Validators.required),
        email: new FormControl(null, [Validators.required, Validators.email]),
        phoneNumber: new FormControl(null),
    });

    formSent = false;

    constructor(private apiService: ApiService, private activeModal: NgbActiveModal) {
    }

    setSelectedCompanyType(selectedCompanyType: CompanyType): void {
        this.contactForm.controls.companyType.setValue(selectedCompanyType);
    }

    ngOnInit(): void {
    }

    close(): void {
        this.activeModal.close();
    }

    submitForm(): void {
        const type = this.contactForm.controls.companyType.value === 'Specialist' ? ' (Specialist Clinic)' : ' (Dental Lab)';
        const form = {
            Name: this.contactForm.controls.contactName.value,
            Email: this.contactForm.controls.email.value,
            PhoneNumber: this.contactForm.controls.phoneNumber.value,
            PracticeName: this.contactForm.controls.companyName.value + type
        };
        this.formSent = true;
        this.contactForm.reset();
        this.apiService.sendForm(form).subscribe(() => {
        }, error => {
            this.formSent = false;
            console.log(error);
        });
    }
}

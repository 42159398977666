import {Injectable} from '@angular/core';
import {HttpHeaders, HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(private http: HttpClient) { }

  public createHeaders(): HttpHeaders {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      Accept: 'application/json',
    });
  }

  public sendForm(contactForm): Observable<any> {
    const url = `${environment.apiBaseUrl}/contactme`;
    const headers = this.createHeaders();
    return this.http.post<any>(url, contactForm, {headers});
  }
}

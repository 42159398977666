<div class="container-fluid teth-container">
    <div class="row row-flip">
        <div class="col-12 col-lg-4 align-self-center">
            <p class="prac-title" i18n>Optimize Dental Lab Performance</p>
            <p class="prac-subtitle mb-3" i18n>Accept and Manage Lab Referrals 24/7 and Increase Your Referral Base</p>
            <p class="prac-details mb-3" i18n>
                Improve lab logistics and enable real-time communication with clinics on all cases.
            </p>
            <button [routerLink]="'/demo'" class="teth-button blue-button mb-4" i18n>Learn More</button>
        </div>
        <div class="col-12 col-lg-6 offset-lg-1">
            <img class="img-fluid" [src]="'assets/img/labs/lab-hero.svg'" alt="Tetherport for Specialists">
        </div>
    </div>
    <div class="row">
        <div>
            <img class="img-fluid mt-4" [src]="'assets/img/labs/tetherport-for-labs.svg'">
        </div>
    </div>
    <div class="row teth-row">
        <div class="col-12 col-lg-6">
            <img class="img-fluid" [src]="'assets/img/labs/lab-1.svg'" alt="Tetherport Lab Dashboard 1">
        </div>
        <div class="col-12 col-lg-4 align-self-center offset-lg-1">
            <div class="prac-circle lab-circle">
                1
            </div>
            <p class="why-title" i18n>Real-Time Communications</p>
            <p class="why-details" i18n>
                Eliminate communications issues with clinics by receiving all information digitally and connecting securely with clinic staff.
            </p>
        </div>
    </div>
    <div class="row teth-row grey-row row-flip">
        <div class="col-12 col-lg-4 align-self-center">
            <div class="prac-circle lab-circle">
                2
            </div>
            <p class="why-title" i18n>Monitor Progress for All Cases</p>
            <p class="why-details" i18n>
                Easily organize all incoming lab cases from the time that they are received until the time that they are completed.
            </p>
        </div>
        <div class="col-12 col-lg-6 offset-lg-1">
            <img class="img-fluid" [src]="'assets/img/labs/lab-2.svg'" alt="Tetherport Specialist Dashboard 2">
        </div>
    </div>
    <div class="row teth-row">
        <div class="col-12 col-lg-6">
            <img class="img-fluid" [src]="'assets/img/labs/lab-3.svg'" alt="Tetherport Specialist Dashboard 3">
        </div>
        <div class="col-12 col-lg-4 align-self-center offset-lg-1">
            <div class="prac-circle lab-circle">
                3
            </div>
            <p class="why-title" i18n>Identify Urgent Cases and Pick-ups</p>
            <p class="why-details" i18n>
                Plan your workflow based on accurate information about urgent lab cases as well as physical pick-up requests.
            </p>
        </div>
    </div>
</div>

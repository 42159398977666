import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {debounceTime} from 'rxjs/operators';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss']
})
export class NavComponent implements OnInit {

  constructor(private router: Router) { }

  hideNavBarOnRoutes = ['/one-dentsplysirona'];
  hideNavBar = false;

  ngOnInit(): void {
    this.router.events.pipe(debounceTime(100)).subscribe(() => {
      this.hideNavBar = this.hideNavBarOnRoutes.includes(this.router.url);
    });
  }
}

<div class="container-fluid teth-container">
    <div class="row row-flip">
        <div class="col-lg-4 col-12 align-self-center">
            <p class="prac-title" i18n>Efficient and Accurate Communications</p>
            <p class="prac-subtitle mb-3" i18n>Accurately, Efficiently and Securely Create & Monitor Outgoing Referrals</p>
            <p class="prac-details mb-3" i18n>
                Establish a direct line of communication for all outgoing lab cases and specialist referrals.
            </p>
            <button [routerLink]="'/demo'" class="teth-button purple-button mb-4" i18n>Learn More</button>
        </div>
        <div class="col-lg-6 col-12 offset-lg-1">
            <img class="img-fluid" [src]="'assets/img/generalists/dentist-hero.svg'" alt="Tetherport for Generalists">
        </div>
    </div>
    <div class="row">
        <div>
            <img class="img-fluid mt-4" [src]="'assets/img/generalists/tetherport-for-dentists.svg'">
        </div>
    </div>
    <div class="row teth-row">
        <div class="col-lg-6 col-12">
            <img class="img-fluid" [src]="'assets/img/generalists/dentist-1.svg'" alt="Tetherport Generalist Dashboard 1">
        </div>
        <div class="col-lg-4 align-self-center offset-lg-1 col-12">
            <div class="prac-circle generalist-circle">
                1
            </div>
            <p class="why-title" i18n>Manage Communication with Dental Labs</p>
            <p class="why-details" i18n>
                Streamlined and efficient digital forms allow for easy and efficient lab requests.
            </p>
        </div>
    </div>
    <div class="row teth-row grey-row row-flip">
        <div class="col-lg-4 align-self-center col-12">
            <div class="prac-circle generalist-circle">
                2
            </div>
            <p class="why-title" i18n>Find Dental Specialists and Securely Send Referrals</p>
            <p class="why-details" i18n>
                An open communication system between general dentists and dental specialists that results in well coordinated patient treatment.
            </p>
        </div>
        <div class="col-lg-6 offset-lg-1 col-12">
            <img class="img-fluid" [src]="'assets/img/generalists/dentist-2.svg'" alt="Tetherport Generalist Dashboard 2">
        </div>
    </div>
    <div class="row teth-row">
        <div class="col-lg-6 col-12">
            <img class="img-fluid" [src]="'assets/img/generalists/dentist-3.svg'" alt="Tetherport Generalist Dashboard 3">
        </div>
        <div class="col-lg-4 align-self-center offset-lg-1 col-12">
            <div class="prac-circle generalist-circle">
                3
            </div>
            <p class="why-title" i18n>Monitor Progress for All Outstanding Cases</p>
            <p class="why-details" i18n>
                Every detail about all specialist referrals and lab cases at your finger tips — with status updates, direct messaging and official correspondence letters, your office will never miss a beat.
            </p>
        </div>
    </div>
</div>

import {Component, OnInit} from '@angular/core';
import {SafeResourceUrl} from '@angular/platform-browser';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

    whyCards: any[] = [
        {
            src: '../assets/img/why-1.svg',
            title: $localize`Secure Referral Management`,
            details: $localize`An accurate, efficient and secure referral portal designed to link general dentists with dental specialists and dental labs resulting in saved time and effort.`
        },
        {
            src: '../assets/img/why-2.svg',
            title: $localize`Efficient Patient Scheduling`,
            details: $localize`A system that enables specialist staff to manage incoming referrals, efficiently schedule appointments and improve case acceptance and revenue.`
        },
        {
            src: '../assets/img/why-3.svg',
            title: $localize`Optimized Revenue Performance`,
            details: $localize`Recommendation engines and data analysis that generate proactive strategies for optimizing revenue performance.`
        },
        {
            src: '../assets/img/why-4.svg',
            title: $localize`Coordinated Patient Treatment`,
            details: $localize`An open communication channel between general dentists, dental specialists and dental labs that results in well coordinated patient treatment.`
        }
    ];

    constructor() {
    }

    ngOnInit(): void {
    }

    getUrlForVideo(): SafeResourceUrl {
        return 'https://s3.ca-central-1.amazonaws.com/tetherport.ca/helpvideos/Tetherport.mp4';
    }

}

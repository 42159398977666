import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import {LocationStrategy, HashLocationStrategy} from '@angular/common';
import {HttpClientModule} from '@angular/common/http';


import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavComponent } from './components/nav/nav.component';
import { FooterComponent } from './components/footer/footer.component';
import { HomeComponent } from './components/home/home.component';
import { SpecialistsComponent } from './components/specialists/specialists.component';
import { LabsComponent } from './components/labs/labs.component';
import { DentistsComponent } from './components/dentists/dentists.component';
import { DemoComponent } from './components/demo/demo.component';
import {ReactiveFormsModule} from '@angular/forms';
import { OneDentsplyComponent } from './components/one-dentsply/one-dentsply.component';
import { RequestDemoModalComponent } from './components/request-demo-modal/request-demo-modal.component';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  declarations: [
    AppComponent,
    NavComponent,
    FooterComponent,
    HomeComponent,
    SpecialistsComponent,
    LabsComponent,
    DentistsComponent,
    DemoComponent,
    OneDentsplyComponent,
    RequestDemoModalComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    HttpClientModule,
  ],
  providers: [
    NgbActiveModal,
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

import {Component, OnInit} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {CompanyType, RequestDemoModalComponent} from '../request-demo-modal/request-demo-modal.component';
import LocalizationUtils from '../../utils/localization-utils';

@Component({
    selector: 'app-one-dentsply',
    templateUrl: './one-dentsply.component.html',
    styleUrls: ['./one-dentsply.component.scss']
})
export class OneDentsplyComponent implements OnInit {

    oneDSLogoSrc = 'assets/img/ods-logo.png';

    constructor(
        private modalService: NgbModal) {
    }

    ngOnInit(): void {
        this.oneDSLogoSrc = window.location.pathname === '/fr/' ? 'assets/img/ods-logo-fr.png' : 'assets/img/ods-logo.png';
    }

    learnMoreClicked(): void {
        window.open('https://tetherport.ca/', '_blank');
    }

    showDemoModal(companyType: CompanyType): void {
        const modalRef = this.modalService.open(RequestDemoModalComponent, {size: 'md', centered: true});
        (modalRef.componentInstance as RequestDemoModalComponent).setSelectedCompanyType(companyType);
    }

    changeLanuage(): void {
        LocalizationUtils.changeLanguage();
    }
}

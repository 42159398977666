<nav class="navbar navbar-expand-lg navbar-light nav-padding" [class.d-none]="hideNavBar">
    <a href="#" class="navbar-brand">
        <img [src]="'assets/img/tetherport-wordmark-blue.svg'" width="130" height="45.28" alt="Tetherport Logo">
    </a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown"
            aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarNavDropdown">
        <div class="navbar-nav ml-auto">
            <a data-toggle="collapse" data-target=".navbar-collapse" class="nav-item nav-link nav-font" [routerLink]="'/specialists'" routerLinkActive="active"
               [routerLinkActiveOptions]="{exact:true}"><span i18n>Specialists</span></a>
            <a data-toggle="collapse" data-target=".navbar-collapse" class="nav-item nav-link nav-font" [routerLink]="'/labs'" routerLinkActive="active"
               [routerLinkActiveOptions]="{exact:true}"><span i18n>Dental Labs</span></a>
            <a data-toggle="collapse" data-target=".navbar-collapse" class="nav-item nav-link nav-font" [routerLink]="'/dentists'" routerLinkActive="active"
               [routerLinkActiveOptions]="{exact:true}"><span i18n>General Dentists</span></a>
            <a data-toggle="collapse" data-target=".navbar-collapse" class="nav-item nav-link nav-font mr-3" [routerLink]="'/demo'" routerLinkActive="active"
               [routerLinkActiveOptions]="{exact:true}"><span i18n>Schedule Demo</span></a>
            <a href="https://app.tetherport.ca/#/sign-up" class="sign-up-button nav-item" i18n>Sign Up</a>
            <a href="https://app.tetherport.ca/#/sign-in" class="sign-in-button nav-item" i18n>Sign In</a>
        </div>
    </div>
</nav>
<hr class="nav-hr">

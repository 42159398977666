<footer class="page-footer">
    <div class="container-fluid footer-padding" >
        <div class="row">
            <div class="col-lg-3 col-12">
                <img class="mb-2 img-fluid footer-logo" [src]="'assets/img/tetherport-wordmark-blue.svg'" [routerLink]="''" alt="Tetherport Logo">
                <p class="footer-text" i18n>
                    Referral management and business intelligence software for dental specialists, dental labs and general dentists.
                </p>
            </div>
            <div class="col-lg-2 offset-lg-4 col-6">
                <p class="footer-title" i18n>Learn More</p>
                <ul class="list-unstyled">
                    <li class="footer-list-item">
                        <a class="footer-text" [routerLink]="'/specialists'" i18n>Specialists</a>
                    </li>
                    <li>
                        <a class="footer-text" [routerLink]="'/labs'" i18n>Dental Labs</a>
                    </li>
                    <li>
                        <a class="footer-text" [routerLink]="'/dentists'" i18n>General Dentists</a>
                    </li>
                    <li>
                        <a class="footer-text" [routerLink]="'/demo'" i18n>Schedule Demo</a>
                    </li>
                </ul>
            </div>
            <div class="col-lg-3 col-6">
                <p class="footer-title" i18n>Contact Info</p>
                <p class="footer-text" i18n>3344 Albert Street</p>
                <p class="footer-text" i18n>Regina, Saskatchewan, S4S 3P1</p>
                <p class="footer-text" i18n>Canada</p>

                <p class="footer-text" i18n>Email: info@tetherport.ca</p>
            </div>
        </div>
        <hr>
        <div class="row">
            <div class="col-12 mb-3">
                <p class="copyright-text" i18n>Copyright © 2021 Tetherport All rights reserved.</p>
            </div>
        </div>
    </div>
</footer>

<div class="container-fluid teth-container ods-main-header">
    <div class="ods-bg">
    </div>
    <div class="row position-relative">
        <div class="col-12 d-flex justify-content-end">
            <div class="change-language" (click)="changeLanuage()" i18n>
                Français
            </div>
        </div>
        <div class="col-lg-6 col-12 align-self-center">
            <p class="prac-title tp-ods-logos mb-3">
                <img [src]="'assets/img/tetherport-wordmark-blue.svg'" alt="" class="teth-logo mr-4"> +
                <img [src]="oneDSLogoSrc" alt="" class="ods-logo ml-4">
            </p>
            <p class="prac-subtitle mb-3" i18n>Referral Management & Business Intelligence Software</p>
            <p class="prac-details mb-3" i18n>
                Tetherport is an accurate, efficient and secure referral system designed to propel business growth and
                streamline administrative processes. Discover the One DS Member Advantage with special offers from
                Tetherport.
            </p>
            <button (click)="learnMoreClicked()" class="teth-button navy-button learn-more-button mt-3" i18n>
                Learn More About Tetherport
            </button>
        </div>
    </div>
</div>
<div class="container-fluid teth-container pt-3">
    <div class="row">
        <div class="col-lg-6 col-12">
            <img class="img-fluid" [src]="'assets/img/generalists/dentist-1.svg'"
                 alt="">
        </div>
        <div class="col-lg-4 align-self-center offset-lg-1 col-12">
            <p class="why-title mb-4" i18n>Tetherport for General Dentists</p>
            <p class="why-details mb-3" i18n>
                Accurately, efficiently and securely create & track outgoing referrals. Establish a direct line of
                communication with your network of specialist clinics and dental labs.
                <br><br>
                One DS Member Advantage: Free access to Tetherport Basic Package
            </p>
            <button onclick="window.location.href='https://app.tetherport.ca/#/sign-up?source=ods'"
                    class="teth-button navy-button learn-more-button mt-3" i18n>
                Sign Up For Free
            </button>
        </div>
    </div>
    <div class="row row-flip">
        <div class="col-lg-4 align-self-center col-12">
            <p class="why-title mb-4" i18n>Tetherport for Dental Specialists</p>
            <p class="why-details mb-3" i18n>
                Enhance and grow your practice with a secure referral management system. Increase your referral base and
                minimize lost revenue due to missed referrals.
                <br><br>
                One DS Member Advantage: 3 Month Free Trial + 20% Discount
            </p>
            <button (click)="showDemoModal('Specialist')"
                    class="teth-button navy-button learn-more-button mt-3" i18n>
                Request Demo
            </button>
        </div>
        <div class="col-lg-6 offset-lg-1 col-12">
            <img class="img-fluid" [src]="'assets/img/specialists/specialist-1.svg'"
                 alt="">
        </div>
    </div>
    <div class="row">
        <div class="col-lg-6 col-12">
            <img class="img-fluid" [src]="'assets/img/labs/lab-1.svg'"
                 alt="">
        </div>
        <div class="col-lg-4 align-self-center offset-lg-1 col-12">
            <p class="why-title mb-4" i18n>Tetherport for Dental Labs</p>
            <p class="why-details mb-3" i18n>
                Accept and manage lab referrals 24/7 and increase your referral base. Streamline your dental labs
                logistics and reduce administrative expenses.
                <br><br>
                One DS Member Advantage: 3 Month Free Trial + 20% Discount
            </p>
            <button (click)="showDemoModal('DentalLab')" 
                    class="teth-button navy-button learn-more-button mt-3" i18n>
                Request Demo
            </button>
        </div>
    </div>
</div>

<div class="container-fluid teth-container">
    <div class="row teth-row row-flip">
        <div class="col-12 col-lg-5 col-5">
            <p class="hero-header" i18n>
                Referral Management and Business Intelligence Software for Dental Specialists, General Dentists & Dental Labs
            </p>
            <p class="hero-details" i18n>
                An accurate, efficient and secure referral portal designed to propel business growth.
            </p>
            <button [routerLink]="'/demo'" class="teth-button green-button mb-4" i18n>
                Learn More
            </button>
        </div>
        <div class="col-12 col-lg-4 offset-lg-2 mb-3">
            <img class="img-fluid" [src]="'assets/img/homepage-hero.svg'" alt="Tetherport Hero Image">
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <video *ngIf="(getUrlForVideo())"
                   [src]="(getUrlForVideo())"
                   class="video-container"
                   controls
                   poster="./assets/img/video-thumb1.png">
            </video>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <img class="img-fluid divider-img" [src]="'assets/img/why-tetherport.svg'" alt="Tetherport Divider">
        </div>
    </div>
    <div class="row teth-row mt-4">
        <div class="col-lg-3 col-12" *ngFor="let c of whyCards">
            <img class="img-fluid why-image" src="{{c.src}}" alt="Why Tetherport">
            <div class="why-text">
                <p class="why-title two-line">{{c.title}}</p>
                <p class="why-details">{{c.details}}</p>
            </div>
        </div>
    </div>
    <hr>
    <div class="row teth-row row-flip mb-5 mt-5">
        <div class="col-lg-4 col-12 align-self-center">
            <p class="why-title" i18n>Tetherport for Dental Specialists</p>
            <p class="why-details" i18n>
                Enhance and grow your practice with a secure referral management system.
                Increase your referral base and minimize lost revenue due to missed referrals.
            </p>
            <button [routerLink]="'/specialists'" class="teth-button navy-button mb-4" i18n>Learn More</button>
        </div>
        <div class="col-lg-6 col-12 offset-lg-1">
            <img class="img-fluid mb-5" [src]="'assets/img/tp-specialists.svg'" alt="Tetherport for Specialists">
        </div>
    </div>
    <div class="row teth-row mb-5 mt-5">
        <div class="col-lg-6 col-12">
            <img class="img-fluid mb-5" [src]="'assets/img/tp-general-dentists.svg'" alt="Tetherport for General Dentists">
        </div>
        <div class="col-lg-4 col-12 align-self-center offset-lg-1">
            <p class="why-title" i18n>Tetherport for General Dentists</p>
            <p class="why-details" i18n>
                Accurately, efficiently and securely create & track outgoing referrals.
                Establish a direct line of communication with specialist clinics and dental labs.
            </p>
            <button [routerLink]="'/dentists'" class="teth-button purple-button mb-4" i18n>Learn More</button>
        </div>
    </div>
    <div class="row teth-row row-flip mb-5 mt-5">
        <div class="col-lg-4 col-12 align-self-center">
            <p class="why-title" i18n>Tetherport for Dental Labs</p>
            <p class="why-details" i18n>Accept and manage lab referrals 24/7 and increase your referral base. Streamline
                dental labs logistics and reduce administration expenses.</p>
            <button [routerLink]="'/labs'" class="teth-button blue-button mb-4" i18n>Learn More</button>
        </div>
        <div class="col-lg-6 col-12 offset-lg-1">
            <img class="img-fluid mb-5" [src]="'assets/img/tp-dental-labs.svg'" alt="Tetherport for Dental Labs">
        </div>
    </div>
</div>

<div class="mt-5">
    <div class="demo-container vertical-center">
        <div class="text-center">
            <p class="demo-header" i18n>Still Unsure?</p>
            <p class="demo-details" i18n>Let us show you how you can grow your business with Tetherport.</p>
            <button [routerLink]="'/demo'" class="teth-button green-button" i18n>Schedule a Demo</button>
        </div>
    </div>
</div>

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { DemoComponent } from './components/demo/demo.component';
import { DentistsComponent } from './components/dentists/dentists.component';
import { HomeComponent } from './components/home/home.component';
import { LabsComponent } from './components/labs/labs.component';
import { SpecialistsComponent} from './components/specialists/specialists.component';
import {OneDentsplyComponent} from './components/one-dentsply/one-dentsply.component';

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'demo', component: DemoComponent },
  { path: 'dentists', component: DentistsComponent },
  { path: 'specialists', component: SpecialistsComponent },
  { path: 'labs', component: LabsComponent },
  { path: 'one-dentsplysirona', component: OneDentsplyComponent },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
